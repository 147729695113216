

























































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import MainContent from '@/components/MainContent.vue'
import NavMenu from '@/components/NavMenu.vue'
import HeaderBeforeLogin from "@/components/HeaderBeforeLogin.vue"
import FooterHome from '@/components/FooterHome.vue'
import FooterMobile from '@/components/FooterMobile.vue'

@Component({ 
    components: {
        Header,
        MainContent,
        NavMenu,
        HeaderBeforeLogin,
        FooterHome,
        FooterMobile
    }
 })
export default class HelpCenter extends Vue {
  private currentUrl: any = window.location.origin;

  created() {
    this.currentUrl = window.location.origin
  }
}
